<div class="customers">
  <h2 class="customers__heading2">Our customers</h2>

  <ngb-carousel [interval]="0">
    <ng-template ngbSlide>
      <div class="simp-row">
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/anz.png"
            alt="anz"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/ubank.svg"
            alt="ubank"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/colcap.png"
            alt="columbus capital"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/afg.png"
            alt="afg"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/connective.png"
            alt="connective"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/LMG.png"
            alt="loan market"
            class="customers__slide-image"
          />
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="simp-row">
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/citibank.png"
            alt="citi"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/homestar.png"
            alt="homestar"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/bnz.png"
            alt="bnz"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/axis-lending.png"
            alt="axis lending"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/rate-money.png"
            alt="rate"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/brighten.jpg"
            alt="brighten"
            class="customers__slide-image"
          />
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="simp-row">
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/all-state.png"
            alt="all state"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/AAA - Copy.png"
            alt="aaa"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/Advantedge - small - Copy.png"
            alt="Advantedge"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/ASCENTEON Home Loan 642x322 - Copy.png"
            alt="ASCENTEON"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/AustFinancial - Copy.png"
            alt="AustFinancial"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/BC Invest - Copy.png"
            alt="bc invest"
            class="customers__slide-image"
          />
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="simp-row">
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/bmm_ - Copy.png"
            alt="bmm"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/Funding Logo.png"
            alt="Funding"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/Granite Home Loans_logo.jpg"
            alt="Granite"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/Hume Bank - Copy.png"
            alt="Hume"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/idutch - Copy.png"
            alt="idutch invest"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/kiwibank.jpg"
            alt="kiwibank"
            class="customers__slide-image"
          />
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="simp-row">
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/LaTrobe.jpg"
            alt="LaTrobe"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/Lixi.jpg"
            alt="Lixi"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/MAB.png"
            alt="MAB"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/mezy.png"
            alt="mezy"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/mortgage house.png"
            alt="mortgage house"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/mortgage mart.jpg"
            alt="mortgage mart"
            class="customers__slide-image"
          />
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="simp-row">
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/Move Bank.bmp"
            alt="Move"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/RedZed.png"
            alt="RedZed"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/specialist.png"
            alt="specialist"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/ThinkTank_CMYK.jpg"
            alt="ThinkTank_CMYK"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/order financial.png"
            alt="order"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/Origin.png"
            alt="Origin"
            class="customers__slide-image"
          />
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="simp-row">
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/P&NBankLogo.jpg"
            alt="P&N"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/pccu-logo.png"
            alt="pccu-logo"
            class="customers__slide-image"
          />
        </div>
        <div class="col-2 customers__slide-image-container">
          <img
            src="./../../../../assets/images/PepperMoney.png"
            alt="PepperMoney"
            class="customers__slide-image"
          />
        </div>
      </div>
    </ng-template>
  </ngb-carousel>
</div>
