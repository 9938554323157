<div class="digital-platform">
  <h2 class="digital-platform__heading2">Submit higher quality broker applications with less effort</h2>

  <div class="digital-platform__box">
    <div class="digital-platform__box-top">Simpology’s fully digital broker platform provides</div>
    <br>
    <div class="simp-row">
      <div class="col-3 digital-platform__item1">Built in lender specific validation including application rules and serviceability</div>
      <div class="col-3 digital-platform__item2">Seamless integration into aggregator CRMs</div>
      <div class="col-3 digital-platform__item3">E-lodge direct to lender loan origination systems</div>
      <div class="col-3 digital-platform__item4">No double handling of data, data is simply enhanced as the application progresses</div>
    </div>
    <br>
    <div class="digital-platform__box-bottom">Fully self-managed and customisable solution</div>
  </div>

  <div class="digital-platform__screenshots">
    <img src="./../../assets/images/borrower-cloud.svg" alt="Borrower" class="digital-platform__borrower">
    <img src="./../../assets/images/aggregator-cloud.svg" alt="Aggregator" class="digital-platform__aggregator">
    <img src="./../../assets/images/broker-cloud.svg" alt="Broker" class="digital-platform__broker">
    <img src="./../../assets/images/lender-cloud.svg" alt="Lender" class="digital-platform__lender">
  </div>
</div>