<div class="manage-applications">
  <div class="manage-applications__image-container">
    <img src="./../../assets/images/timeline.svg" alt="" class="manage-applications__timeline">
    <img src="./../../assets/images/omni.svg" alt="" class="manage-applications__omni">
    <img src="./../../assets/images/pending-actions.svg" alt="" class="manage-applications__pending-actions">
  </div>
  <div class="manage-applications__text-container">
    <h2 class="manage-applications__heading2">Collaborate and manage applications across all participants</h2>
    <h6 class="manage-applications__heading6">A single unifying dashboard covers all interactions for an application, including 3rd party providers, and back-channel.</h6>
    
    <p class="manage-applications__highlights">Universal communication channel</p>
    <p class="manage-applications__highlights">Information requests</p>
    <p class="manage-applications__highlights">Action items</p>
    <p class="manage-applications__highlights">Customisable workflow</p>
    <p class="manage-applications__highlights">Accountability, visibility, efficiency</p>
    <p class="manage-applications__highlights">Notes and audit logging</p>
    <p class="manage-applications__highlights">Automated alerts and escalations</p>
    <p class="manage-applications__highlights">Back-channel messages</p>
    <p class="manage-applications__highlights">Timelines and milestones</p>
  </div>
</div>