<div class="intro">
  <h1 class="intro__heading1">
    Leading the way to rapid loan application decisioning
  </h1>
  <h4 class="intro__heading4 intro__heading4--narrow">
    Simpology’s best in class digital loan origination platform powers
    end-to-end smart loan application journeys
  </h4>

  <div class="intro__screenshots">
    <img
      src="./../../assets/images/Applicant.svg"
      alt="Applicant"
      class="intro__applicant"
    />
    <img
      src="./../../assets/images/Broker.svg"
      alt="Broker"
      class="intro__broker"
    />
    <img
      src="./../../assets/images/Lender.svg"
      alt="Lender"
      class="intro__lender"
    />
  </div>
</div>
