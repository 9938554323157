import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { environment } from 'src/environments/environment';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  public form: FormGroup;
  public validationErrors: ValidationErrors;
  public formSubmitted = false;
  public apiError = false;

  public get name() {
    return this.form.controls['name'];
  }

  public get email() {
    return this.form.controls['email'];
  }

  public get mobilePhone() {
    return this.form.controls['mobilePhone'];
  }

  public get message() {
    return this.form.controls['message'];
  }

  public constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient
  ) {}

  public ngOnInit(): void {
    this.validationErrors = {
      name: {
        required: 'Name is required',
        minlength: 'Name cannot be less than 2 characters',
        pattern: 'Enter a valid name',
      },
      email: {
        required: 'Email is required',
        pattern: 'Enter a valid email',
      },
      mobilePhone: {
        pattern: 'Enter a valid mobile number',
      },
      message: {
        required: 'Please enter a message',
      },
    };

    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern(/^[A-Za-z][A-Za-z'-]+([ A-Za-z][A-Za-z'-]+)*/),
        ],
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i
          ),
        ],
      ],
      mobilePhone: ['', Validators.pattern(/^(\+614|0?4)\d{8}$/)],
      message: ['', Validators.required],
      siteName: ['Simpology website', Validators.required],
    });
  }

  public onSubmit(): void {
    if (this.form.invalid) {
      return;
    }

    this.form.disable();
    this.apiError = false;

    this.http
      .post(`${environment.publicServiceUrl}api/1.0/contact`, {
        Name: this.form.value.name,
        Email: this.form.value.email,
        MobilePhone: this.form.value.mobilePhone,
        Message: this.form.value.message,
        SiteName: this.form.value.siteName,
      })
      .pipe(
        finalize(() => {
          this.formSubmitted = true;
          this.form.enable();
        })
      )
      .subscribe(
        (result: any) => {},
        (error: any) => (this.apiError = true)
      );
  }

  public getErrorMessage(formControlName: string): string {
    return this.validationErrors[formControlName][
      Object.keys(this.form.get(formControlName).errors)[0]
    ];
  }

  public resetForm(): void {
    this.formSubmitted = false;
  }
}
