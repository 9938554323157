import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'manage-applications',
  templateUrl: './manage-applications.component.html',
  styleUrls: ['./manage-applications.component.scss']
})
export class ManageApplicationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
