import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'digital-platform',
  templateUrl: './digital-platform.component.html',
  styleUrls: ['./digital-platform.component.scss']
})
export class DigitalPlatformComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
