<div class="operational-efficiency">
  <h2 class="operational-efficiency__heading2">
    Increase operational efficiency. Rapid time to decision
  </h2>
  <h6 class="operational-efficiency__heading6">
    The Simpology ecosystem provides unparalleled opportunities for efficiency
    and productivity. Brokers, lenders, and all application participants have
    access to the right information at the right time. Checks and balances
    ensure applications are compliant and serviceable ensuring acceptance and
    fostering high approval rates.
  </h6>
  <h6 class="operational-efficiency__heading6">
    Built in communication and workflow tools such as information requests
    support the direct flow of information between parties at different steps of
    the process.
  </h6>

  <ngb-carousel [interval]="0" class="simp-margin-bottom-large w-100">
    <ng-template ngbSlide>
      <div>
        <img
          src="./../../../../assets/images/operational-efficiency1.png"
          alt="first slide"
          class="operational-efficiency__slide-image"
        />
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div>
        <img
          src="./../../../../assets/images/operational-efficiency2.png"
          alt="second slide"
          class="operational-efficiency__slide-image"
        />
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div>
        <img
          src="./../../../../assets/images/operational-efficiency3.png"
          alt="third slide"
          class="operational-efficiency__slide-image"
        />
      </div>
    </ng-template>
  </ngb-carousel>

  <div class="simp-row simp-margin-top-large">
    <p class="col-md-3 operational-efficiency__highlights">
      Information requests (IR/MIR)
    </p>
    <p class="col-md-3 operational-efficiency__highlights">
      Checklists and print forms
    </p>
    <p class="col-md-3 operational-efficiency__highlights">
      Leverages built in, update to date, lender data and serviceability
    </p>
    <p class="col-md-3 operational-efficiency__highlights">
      Maximise digital interactions with out-of-the-box 3rd party integrations
    </p>
    <p class="col-md-3 operational-efficiency__highlights">
      Supporting documents
    </p>
    <p class="col-md-3 operational-efficiency__highlights">
      Application validation workflow side-by-side review
    </p>
  </div>
</div>
