<div class="consumer-journeys">
  <div class="consumer-journeys__text-container">
    <h2 class="consumer-journeys__heading2">Create and deploy unique consumer journeys easily</h2>
    <h6 class="consumer-journeys__heading6">Seamless application journeys ready to go. Use as is, customise, or build your own. Own the opportunity from initial contact through to approval. Minimise drop off with great consumer engagement.</h6>
  
    <ul class="simp-row consumer-journeys__list">
      <li class="col-lg-6">Prequalification</li>
      <li class="col-lg-6">Apply for loan</li>
      <li class="col-lg-6">Fact Find</li>
      <li class="col-lg-6">Banking profile</li>
      <li class="col-lg-6">Statement of position</li>
      <li class="col-lg-6">Secure document portal</li>
      <li class="col-lg-6">Instant mortgage</li>
      <li class="col-lg-6">MIR engine</li>
    </ul>
  </div>
  <div class="consumer-journeys__screenshots">
    <img src="./../../assets/images/journey1.svg" alt="" class="consumer-journeys__journey1">
    <img src="./../../assets/images/journey2.svg" alt="" class="consumer-journeys__journey2">
    <img src="./../../assets/images/journey3.svg" alt="" class="consumer-journeys__journey3">
    <img src="./../../assets/images/journey4.svg" alt="" class="consumer-journeys__journey4">
  </div>
</div>