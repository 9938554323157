import { Component, ElementRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @ViewChild('app', { static: false })
  public app!: ElementRef;

  public currentRoute = '';
  public modalMode = false;
  public showMenu = false;

  constructor(private router: Router) {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag('config', 'UA-192614316-3', {
          page_path: event.urlAfterRedirects,
        });
        this.setPageTitle(event.url);
      });
  }

  public toggleMenu(): void {
    if (this.modalMode && this.router.url.indexOf('/contact-us') !== -1) {
      this.router.navigateByUrl('/home');
    } else if (!this.modalMode) {
      this.showMenu = true;
    } else {
      this.showMenu = false;
    }
    this.modalMode = !this.modalMode;
  }

  public gotToHome(): void {
    this.router.navigateByUrl('home');
  }

  private setPageTitle(url: string): void {
    this.currentRoute = url;
    this.app.nativeElement.scrollTop = 0;
    if (url.indexOf('/contact-us') !== -1) {
      this.modalMode = true;
    } else {
      this.modalMode = false;
    }
    this.showMenu = false;
  }
}
