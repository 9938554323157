<div class="leaders">
  <h1 class="leaders__heading1">Leadership team</h1>
  <h4 class="leaders__heading4">
    We believe in innovation and disruption and we’re backed by Australia’s most
    striking mortgage experts and investors
  </h4>

  <div class="simp-row leaders__intro-container">
    <div class="col-md-4 leaders__intro">
      <img
        src="./../../assets/images/kate-gubbins.svg"
        alt="Kate Gubbins"
        class="leaders__photo"
      />
      <h3 class="leaders__name">Kate Gubbins</h3>
      <h5 class="leaders__title">CEO & Founder</h5>
      <p class="leaders__introduction">
        Prior to Simpology Kate owned and operated a Mortgage Management
        company, Domain Financial Services, with wholesale funding relationships
        with Adelaide Bank and ANZ Bank, and with $1.2Billion in loans under
        management. Kate sold Domain to Firstfolio at the end of 2008.
        Importantly, Kate held business-critical relationships with Aggregators,
        Brokers, Lenders, Mortgage Insurers, Valuers and Panel Solicitors, and
        she has first-hand understanding of the mortgage origination and
        assessment processes.
      </p>
    </div>

    <div class="col-md-4 leaders__intro">
      <img
        src="./../../assets/images/dave-jacobson.svg"
        alt="David Jacobson"
        class="leaders__photo"
      />
      <h3 class="leaders__name">David Jacobson</h3>
      <h5 class="leaders__title">Chairman</h5>
      <p class="leaders__introduction">
        Dave was Managing Director of MacromatiX, a global leader in cloud-based
        retail and hospitality management solutions designed to increase
        productivity and operational excellence, in inventory and supply chain
        management. MacromatiX was successfully sold in 2012 to TPG, a leading
        global private investment firm with $66billion of capital under
        management. TPG has since created RedBook Connect, which includes the
        MacromatiX solution, and services 15 of the world’s 20 largest
        Restaurant and Hospitality Brands.
      </p>
    </div>

    <div class="col-md-4 leaders__intro">
      <img
        src="./../../assets/images/saul-kaplan.png"
        alt="Saul Kaplan"
        class="leaders__photo"
      />
      <h3 class="leaders__name">Saul Kaplan</h3>
      <h5 class="leaders__title">CTO</h5>
      <p class="leaders__introduction">
        Saul has 30 years’ global experience in the IT Industry, and thrives on
        innovation and disruption. Saul co-founded Flare HR in 2015, the
        ultimate HR and benefits solution, exiting to Point72 Ventures in 2018.
        Saul was the founder of MacromatiX, the global leader in cloud-based
        retail and hospitality management solutions. Saul lead the technology
        and product role at MacromatiX as CTO until the company’s acquisition by
        TPG Capital in 2012. Saul brings a wealth of technology, start-up, and
        product experience to his role at Simpology.
      </p>
    </div>
  </div>
</div>

<div class="directors">
  <div class="simp-row leaders__intro-container">
    <div class="col-md-4 leaders__intro">
      <h3 class="leaders__name">David Fuchs</h3>
      <h5 class="leaders__title">Director</h5>
      <p class="leaders__introduction">
        David is the Principal of his family’s investment business. David was the Chief Investment Officer of the Lowy Family Group (LFG), in New York and Sydney, for 13 years in 2008-2021. David continues to be closely associated with LFG as a member of its Advisory Board and as LFG’s representative on certain private company Boards. Prior to LFG, David worked for over 12 years in the areas of principal investment (Babcock & Brown), private equity (Pacific Equity Partners) and investment banking (Morgan Stanley). David has served on several private and public company Boards and been involved in various philanthropic activities. David graduated in 1995 with a Bachelor of Commerce and Bachelor of Laws at UNSW Sydney.
      </p>
    </div>

    <div class="col-md-4 leaders__intro">
      <h3 class="leaders__name">Paul Heilig</h3>
      <h5 class="leaders__title">Director</h5>
      <p class="leaders__introduction">
        Paul is a veteran banker who has over 20 years’ experience in the
        banking and mortgage broking industry. Paul was employed by Investec, a
        specialist bank and asset manager, in South Africa, for 10 years and
        assisted in setting up their operations in Australia. Paul was the
        co-founder of Kingsbridge & Eagle, a successful and respected mortgage
        broking group. He also has held a senior executive position at Centric
        Wealth.
      </p>
    </div>

    <div class="col-md-4 leaders__intro">
      <h3 class="leaders__name">David Shein</h3>
      <h5 class="leaders__title">Director</h5>
      <p class="leaders__introduction">
        In 1987, David established Com Tech Communications as a specialist
        supplier of networking and communication products. In August 2000, David
        sold Com Tech to Dimension Data at a valuation in excess of $1billion.
        Since then David has held a number of Executive positions including
        Chairman of Macromatix, Mentor to One Big Switch, and most recently
        non-Exec Chairman of Centric Wealth.
      </p>
    </div>
  </div>
</div>
