<div class="loan-data">
  <div class="loan-data__text-container">
    <h2 class="loan-data__heading2">Access all lender loan data and serviceability rules</h2>
    <h6 class="loan-data__heading6">Dynamic real time access to comprehensive lender information, including up-to-date loan data, serviceability calculations, business rules, policy guidelines and lender documents, with automated update alerts. All at your fingertips.</h6>
    
    <div class="simp-row">
      <p class="col-md-6 loan-data__highlights">Single source of truth for products, serviceability & policy</p>
      <p class="col-md-6 loan-data__highlights">Managed and configured directly by data owner</p>
    </div>

    <div class="simp-row loan-data__highlights-row2">
      <p class="col-md-6 loan-data__highlights">Compliance guaranteed from lender to applicant</p>
      <p class="col-md-6 loan-data__highlights">API first distribution to third parties</p>
    </div>
  </div>
  <div class="loan-data__image-container">
    <img src="./../../assets/images/loan-data-cloud.svg" alt="" class="loan-data__api-access-image">
  </div>
</div>