<div class="contact-us">
  <h2 class="contact-us__heading2">Contact us</h2>

  <div class="contact-us__form-container">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="!formSubmitted">
      <div class="simp-row simp-margin-bottom-medium">
        <label [for]="name"> Name </label>
        <input
          id="name"
          type="text"
          class="form-control"
          [class.is-invalid]="name.invalid && (name.dirty || name.touched)"
          [formControl]="name"
        />
        <div class="simp-field-error simp-text-small simp-text--red" *ngIf="(name.dirty || name.touched) && name.errors">
          {{ getErrorMessage('name') }}
        </div>
      </div>

      <div class="simp-row simp-margin-bottom-medium">
        <label [for]="email"> Email </label>
        <input
          id="email"
          type="text"
          class="form-control"
          [class.is-invalid]="email.invalid && (email.dirty || email.touched)"
          [formControl]="email"
        />
        <div class="simp-field-error simp-text-small simp-text--red" *ngIf="(email.dirty || email.touched) && email.errors">
          {{ getErrorMessage('email') }}
        </div>
      </div>

      <div class="simp-row simp-margin-bottom-medium">
        <label [for]="mobile">
          Mobile (if you prefer us to call you)
        </label>
        <input
          id="mobile"
          type="text"
          class="form-control"
          [class.is-invalid]="mobilePhone.invalid && (mobilePhone.dirty || mobilePhone.touched)"
          [formControl]="mobilePhone"
        />
        <div class="simp-field-error simp-text-small simp-text--red" *ngIf="(mobilePhone.dirty || mobilePhone.touched) && mobilePhone.errors">
          {{ getErrorMessage('mobilePhone') }}
        </div>
      </div>

      <div class="simp-row simp-margin-bottom-medium">
        <label [for]="message"> How can we help? </label>
        <textarea
          id="message"
          rows="4"
          class="form-control"
          [class.is-invalid]="message.invalid && (message.dirty || message.touched)"
          [formControl]="message"
        ></textarea>
        <div class="simp-field-error simp-text-small simp-text--red" *ngIf="(message.dirty || message.touched) && message.errors">
          {{ getErrorMessage('message') }}
        </div>
      </div>

      <div class="simp-row">
        <button type="submit" [disabled]="!form.valid" class="btn btn-lg simp-button contact-us__submit col-5 col-sm-4">
          Submit
        </button>
      </div>
    </form>

    <div class="contact-us__heading4" *ngIf="formSubmitted && !apiError">
      Thank you for reaching out to us.
      <br>
      <br>
      We'll be in touch once our team has reviewed your message.
    </div>

    <div class="contact-us__heading4" *ngIf="formSubmitted && apiError">
      Something went wrong.
      <br>
      <br>
      Please <a href="javascript:void(0);" (click)="resetForm()" class="simp-link contact-us__email-link">try again</a>.
    </div>
  </div>
</div>
