<div class="about-us-row">
  <div class="about-us">
    <h2 class="about-us__heading2">About us</h2>
    <h6 class="about-us__heading6">
      In 2006 Simpology set out to build a platform that uses technology truly
      effectively to radically improve the mortgage application process.
    </h6>
    <h6 class="about-us__heading6">
      The founders had a history in mortgage origination, with a deep
      understanding of its processes and common challenges in efficiency.
    </h6>
    <h6 class="about-us__heading6">
      Working over the years with industry participants, users, LIXI and our
      superb development team, we have developed a revolutionary electronic
      lodgement methodology and a suite of supporting applications and services
      which are unparalleled in the industry. Our solutions and applications
      have been adopted by the largest banks and origination entities in
      Australia.
    </h6>

    <a [routerLink]="['/leadership-team']" class="simp-link big-link"
      >See leadership team</a
    >
    <a [routerLink]="['/news']" class="simp-link big-link"
      >Simpology in the news</a
    >
  </div>
  <div class="simpology-meaning">
    <p class="simpology-meaning__term">simpology</p>
    <p class="simpology-meaning__definition">
      The application of technology to create a process eco-system that is
      perfect in its function and elegant in its simplicity
    </p>
    <p class="simpology-meaning__operator">=</p>
    <p class="simpology-meaning__term">simplicity</p>
    <p class="simpology-meaning__definition">
      The quality or condition of being easy to understand or do
    </p>
    <p class="simpology-meaning__operator">+</p>
    <p class="simpology-meaning__term">technology</p>
    <p class="simpology-meaning__definition">
      The application of scientific knowledge for practical purposes, especially
      in industry
    </p>
  </div>
</div>

<div class="contact-us-row">
  <div class="map-location">
    <img
      src="./../../assets/images/simpology-map-pin.svg"
      alt="location"
      class="map-location__map"
    />
  </div>
  <div class="contact-us" id="contactUs">
    <h2 class="contact-us__heading2">Contact us</h2>

    <a href="mailto:info@simpology.com.au" class="simp-link big-link"
      >info@simpology.com.au</a
    >

    <p class="simp-link big-link">
      <a
        href="https://simpology.atlassian.net/servicedesk/customer/portal/1"
        target="_blank"
        class="simp-link big-link"
        >Support portal</a
      >
    </p>
    <br />
    <p class="contact-us__address">8 Boronia Street, Redfern NSW 2016</p>

    <a [routerLink]="['/contact-us']" class="contact-us__button">Contact us</a>
  </div>
</div>
