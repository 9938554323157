<div class="news-article">
  <h3 class="news-article__heading">
    REA Group to partner with leading software provider Simpology
  </h3>
  <h4 class="news-article__sub-heading">
    Home loan experience for consumers and mortgage brokers to become radically
    more efficient through new strategic alliance
  </h4>
  <div class="news-article__contents">
    <div>Media Release</div>
    <p>15 June 2021</p>

    <br />

    <p>
      REA Group today announced that it has acquired a 34% interest in leading
      Australian software company Simpology, a provider of mortgage application
      and e-lodgement solutions for the broking and lending industries. As part
      of the agreement REA Group will take two seats on Simpology’s Board.
    </p>

    <p>
      Leveraging Simpology’s technology, REA will have the ability to seamlessly
      facilitate the direct transmission of digital mortgage applications to a
      broad range of banking and lending partners. Currently, Simpology has deep
      integrations into over 30 lenders and over 12,000 brokers and it is
      looking to aggressively grow in the near term.
    </p>

    <p>
      This transaction follows REA’s March announcement regarding the proposed
      acquisition of Mortgage Choice Limited. It will further accelerate the
      Group’s financial services strategy by providing consumers with even
      greater choice and simplicity when navigating their home loan options,
      while also delivering productivity improvements to REA’s broker network.
    </p>

    <p>
      REA Group Chief Executive Officer, Owen Wilson commented: “REA’s
      investment in Simpology reinforces our commitment to delivering the best
      end-to-end mortgage application solutions for consumers, our brokers and
      their clients, founded on choice and simplicity.
    </p>

    <p>
      “Our partnership will provide a step-change in the loan selection and
      digital application experience REA can deliver the 12 million Australians
      who visit realestate.com.au each month.
    </p>

    <p>
      “At the same time, Simpology’s suite of digital products will deliver
      productivity improvements to our brokers through higher quality loan
      submissions resulting in less re-work, faster loan approval times and
      streamlined business operations, so they can spend more time with their
      clients,” concluded Owen.
    </p>

    <p>
      Simpology Executive Chairman, Dave Jacobson commented: “This is a
      significant strategic milestone for Simpology. We look forward to working
      with the team at REA as their technology enabler to help bring to life
      their vision of building Australia’s leading financial services
      marketplace.”
    </p>

    <p>
      Simpology CEO and Founder, Kate Gubbins added: “We are delighted to have
      an established and respected technology leader like REA invest in our
      business. Our team at Simpology has been working hard on innovative new
      solutions and the investment timing is perfect to bolster our commitment
      to delivering efficiencies to our partners across the industry.”
    </p>

    <p>For more information:</p>

    <div>REA Group</div>
    <div>REA Group Corporate Affairs</div>

    <p>
      About REA Group Ltd: (www.rea-group.com): REA Group Ltd ACN 068 349 066
      (ASX:REA) (“REA Group”) is a multinational digital advertising business
      specialising in property. REA Group operates Australia’s leading
      residential and commercial property websites – realestate.com.au and
      realcommercial.com.au – as well as the leading website dedicated to share
      property, Flatmates.com.au and Spacely, a short-term commercial and
      coworking property website. REA Group owns Smartline Home Loans Pty Ltd,
      an Australian mortgage broking franchise group, and PropTrack Pty Ltd, a
      leading provider of property data services. In Asia, REA Group owns
      leading portals in Malaysia (iproperty.com.my) and Hong Kong
      (squarefoot.com.hk), a prominent portal in China (myfun.com) and a leading
      property review site in Thailand (thinkofliving.com). REA Group holds a
      controlling interest in India’s Elara Technologies Pte. Ltd. which
      operates the established brands of Housing.com, Makaan.com and
      PropTiger.com. REA Group also holds a significant shareholding in property
      websites realtor.com in the US, 99.co and iproperty.com.sg in Singapore
      and rumah123.com in Indonesia.
    </p>

    <p>
      About Simpology: (www.simpology.com.au): Simpology is an Australian
      company focused on making the mortgage application process more efficient.
      Simpology is based in Sydney and provides mortgage origination solutions
      to lending and broker partners across Australia and New Zealand.
      Simpology’s products include Loanapp e-lodgement, PASAAS and Tama
      Origination. Simpology has all the advantages of a small, highly
      innovative and agile company, combined with the financial strength,
      experience and wisdom of larger and more mature organisations.
    </p>
  </div>
</div>
