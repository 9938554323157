import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { NewsComponent } from './news/news.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { LeadershipTeamComponent } from './leadership-team/leadership-team.component';
import { HomeComponent } from './home/home.component';
import { IntroductionComponent } from './home/components/introduction/introduction.component';
import { DigitalPlatformComponent } from './home/components/digital-platform/digital-platform.component';
import { ConsumerJourneysComponent } from './home/components/consumer-journeys/consumer-journeys.component';
import { ManageApplicationsComponent } from './home/components/manage-applications/manage-applications.component';
import { LoanDataComponent } from './home/components/loan-data/loan-data.component';
import { OperationalEfficiencyComponent } from './home/components/operational-efficiency/operational-efficiency.component';
import { TechnologyComponent } from './home/components/technology/technology.component';
import { CustomersComponent } from './home/components/customers/customers.component';
import { AboutUsComponent } from './home/components/about-us/about-us.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    IntroductionComponent,
    DigitalPlatformComponent,
    ConsumerJourneysComponent,
    ManageApplicationsComponent,
    LoanDataComponent,
    OperationalEfficiencyComponent,
    TechnologyComponent,
    CustomersComponent,
    AboutUsComponent,
    ContactUsComponent,
    NewsComponent,
    PrivacyPolicyComponent,
    LeadershipTeamComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
