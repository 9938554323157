<div class="app" #app>
  <header class="app__header" [class.app__header--modal]="modalMode">
    <img
      src="./../assets/images/simpology-logo-white.svg"
      alt="Simpology logo"
      class="app__company-logo"
      (click)="gotToHome()"
    />
    <i
      class="far fa-bars app__menu-button"
      (click)="toggleMenu()"
      *ngIf="!modalMode"
    ></i>
    <i
      class="far fa-times app__menu-button"
      (click)="toggleMenu()"
      *ngIf="modalMode"
    ></i>
  </header>

  <router-outlet *ngIf="!showMenu"></router-outlet>

  <div class="app__menu" *ngIf="showMenu">
    <div><a [routerLink]="['/home']" [class.disabled]="currentRoute === '/' || currentRoute === '/home'">Home</a></div>
    <div><a [routerLink]="['/leadership-team']" [class.disabled]="currentRoute === '/leadership-team'">Leadership team</a></div>
    <div><a [routerLink]="['/news']" [class.disabled]="currentRoute === '/news'">News</a></div>
    <div><a [routerLink]="['/contact-us']" [class.disabled]="currentRoute === '/contact-us'">Contact us</a></div>
    <div>
      <a
        href="https://simpology.atlassian.net/servicedesk/customer/portal/1"
        target="_blank"
        (click)="toggleMenu()"
        >
        Support portal
        <i class="fas fa-external-link-alt simp-margin-left-base app__external-link-icon"></i
      ></a>
    </div>
  </div>

  <footer class="app__footer">
    © Simpology Pty Ltd. All rights reserved.&nbsp;<a
      [routerLink]="['/privacy-policy']"
      class="simp-link app__footer-link"
      >Privacy Policy</a
    >
  </footer>
</div>
