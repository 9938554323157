import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'operational-efficiency',
  templateUrl: './operational-efficiency.component.html',
  styleUrls: ['./operational-efficiency.component.scss']
})
export class OperationalEfficiencyComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
