import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'loan-data',
  templateUrl: './loan-data.component.html',
  styleUrls: ['./loan-data.component.scss']
})
export class LoanDataComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
