<div class="technology">
  <h2 class="technology__heading2">Best in class technology</h2>
  <h6 class="technology__heading6">End-to-end digital mortgage solution that puts the consumer at the center of the experience. Streamline traditional, hybrid, and fully digital application experiences.</h6>

  <div class="technology__box">
    <div class="technology__inner-box">
      <div class="technology__box-item">Purpose built from the ground up</div>
      <div class="technology__box-item">Open architecture</div>
      <div class="technology__box-item">Bank grade security</div>
    </div>
  </div>

  <div class="simp-row technology__blocks-container">
    <div class="col-md-4 technology__block">
      <div class="technology__highlights simp-margin-bottom-large"><span class="technology__dark-text">Simpology Connect</span> delivers comprehensive API access to all functionality, enabling you to build a unique journey to your own exacting standards. Full headless mode supported</div>
      <div class="technology__connect-container">
        <span class="technology__highlights technology__dark-text">Application</span>
        <div class="technology__connect-api-block simp-margin-top-base">
          <button class="technology__connect-button technology__connect-button--get">GET</button>
          <div class="technology__connect-api-url">/api/v1.1/ladm/application</div>
        </div>
        <div class="technology__connect-api-block">
          <button class="technology__connect-button technology__connect-button--post">POST</button>
          <div class="technology__connect-api-url">/api/v1.1/application/document</div>
        </div>
        <div class="technology__connect-api-block">
          <button class="technology__connect-button technology__connect-button--post">POST</button>
          <div class="technology__connect-api-url">/api/v1.1/application/validate</div>
        </div>
        <br>
        <span class="technology__highlights technology__dark-text">Data Subscription</span>
        <div class="technology__connect-api-block simp-margin-top-base">
          <button class="technology__connect-button technology__connect-button--get">GET</button>
          <div class="technology__connect-api-url">/api/v1/data-sub/published...</div>
        </div>
        <div class="technology__connect-api-block">
          <button class="technology__connect-button technology__connect-button--get">GET</button>
          <div class="technology__connect-api-url">/api/v1/data-sub/subscription...</div>
        </div>
      </div>
    </div>

    <div class="col-md-4 technology__block">
      <div class="technology__ssi-image-container simp-margin-bottom-small">
        <img src="./../../../../assets/images/ssi.svg" alt="" class="technology__ssi-image">
      </div>
      <div class="technology__highlights"><span class="technology__dark-text">Shared services infrastructure (SSI)</span> already intergrated with your preferred service providers. This wide collection of integrated technology and data services, delivers fully digital application journeys</div>
      <div class="simp-row simp-margin-top-medium">
        <div class="col-md-6 technology__ssi-feature">Verification of identification</div>
        <div class="col-md-6 technology__ssi-feature">Valuations</div>
        <div class="col-md-6 technology__ssi-feature">Digital bank statements</div>
        <div class="col-md-6 technology__ssi-feature">Insurance</div>
        <div class="col-md-6 technology__ssi-feature">Electronic signing</div>
        <div class="col-md-6 technology__ssi-feature">Equifax, credit bureaus</div>
      </div>    
    </div>

    <div class="col-md-4 technology__block">
      <div class="technology__highlights simp-margin-bottom-large"><span class="technology__dark-text">Simpology’s Loan Application Data Model (LADM)</span> is a comprehensive unifying data object that encompasses the end to end application journey, enabling a single source of truth for all aspects of the application</div>
      <div class="technology__ladm-image-container">
        <img src="./../../../../assets/images/ladm.svg" alt="" class="technology__ladm-image">
      </div>
    </div>
    <div class="col-md-4"></div>
    <div class="col-md-4"></div>
  </div>
</div>
